<template>
  <div class="GrantApplicationTenantForm-wrapper">
    <form-panel :submitUrl="submitUrl"
                :form="form"
                :submitBefore="submitBefore">
      <col2-detail>
        <col2-block title="设置项目授权应用">
          <el-form-item label="选择租户"
                        prop="regionId"
                        :rules="[{ required: true, message: '请选择租户', trigger: 'change' }]">
            <v-select2 v-model="form.regionId"
                       placeholder="选择租户"
                       v-bind="regionParams"
                       @onChange="tenantChange" />
          </el-form-item>
          <el-form-item label="选择项目"
                        prop="communityIds"
                        :rules="[{ required: true, message: '请选择项目', trigger: 'change' }]">
            <chosen-list-panel :list.sync="form.communityIds"
                               @select="selectCommunity" />
          </el-form-item>
          <el-form-item label="授权应用">
            <chosen-list-panel :list.sync="form.subIds"
                               textAs="subName"
                               @select="select" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
    <multi-select title="项目列表"
                  :isShow.sync="showCommunityMultiSelect"
                  :searchUrl="getCommunityListURL"
                  :headers="communityHeaders"
                  :searchParams="form"
                  :responseParams="communityResponseParams"
                  :responseKey="communityResponseKey"
                  :backFill.sync="form.communityIds"
                  :extraParams="communityExtraParams">
      <template #searchSlot>
        <v-input label="项目名称"
                 v-model="form.communityName" />
      </template>
    </multi-select>
    <multi-select title="应用列表"
                  :isShow.sync="showMultiSelect"
                  :searchUrl="getApplicationListURL"
                  :headers="headers"
                  :searchParams="searchParams"
                  :responseParams="responseParams"
                  :responseKey="responseKey"
                  :extraParams="communityExtraParams"
                  :backFill.sync="form.subIds">
      <template #searchSlot>
        <v-input label="应用名称"
                 v-model="searchParams.subName" />
        <v-select label="应用类别"
                  v-model="searchParams.subHandle"
                  :options="subHandleOps" />
        <v-select label="应用类型"
                  v-model="searchParams.subType"
                  :options="subTypeOps" />
        <v-input label="应用描述"
                 v-model="searchParams.subNote" />
      </template>
    </multi-select>
  </div>
</template>

<script>
import { bacthGrantApplicationURL, getApplicationListURL, getCommunityListURL } from './api'
import { subHandleMap, setSubHandleOps, subTypeMap, setSubTypeOps, statusMap, communityStatusMap, setCommunityStatusOps } from '../map'
import { Col2Detail, Col2Block, ChosenListPanel, MultiSelect } from 'components/bussiness'
import { normalCommunityParams, regionParams } from 'common/select2Params'

export default {
  name: 'GrantApplicationTenantForm',
  components: {
    Col2Detail,
    Col2Block,
    ChosenListPanel,
    MultiSelect
  },
  data () {
    return {
      selectRegionId: '',
      subHandleOps: setSubHandleOps(1),
      subTypeOps: setSubTypeOps(1),
      communityParams: normalCommunityParams,
      showCommunityMultiSelect: false,
      showMultiSelect: false,
      form: {
        regionId: '',
        communityIds: [],
        communityName: '',
        subIds: []
      },
      submitUrl: bacthGrantApplicationURL,
      getApplicationListURL,
      getCommunityListURL,
      regionParams,
      communityHeaders: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'communityStatusText',
          label: '项目状态',
          formatter: row => communityStatusMap[row.communityStatus]
        },
        {
          prop: 'communityStage',
          label: '项目阶段'
        },
        {
          prop: 'provinceName',
          label: '所在省'
        },
        {
          prop: 'cityName',
          label: '所在市'
        },
        {
          prop: 'countryName',
          label: '所在区'
        },

        {
          prop: 'regionName',
          label: '分子公司'
        }
      ],
      headers: [
        {
          prop: 'subName',
          label: '应用名称'
        },
        {
          prop: 'subHandleText',
          label: '应用类别',
          formatter: row => subHandleMap[row.subHandle]
        },
        {
          prop: 'subTypeText',
          label: '应用类型',
          formatter: row => subTypeMap[row.subType]
        },
        {
          prop: 'subStatusText',
          label: '应用状态',
          formatter: row => statusMap[row.subStatus]
        },
        {
          prop: 'subNote',
          label: '应用描述'
        }
      ],
      communityResponseParams: {
        id: 'id',
        name: 'communityName'
      },
      searchParams: {
        communityId: '  ',
        subName: '',
        subHandle: undefined,
        subType: undefined,
        subNote: ''
      },
      communityResponseKey: {
        id: 'id',
        name: 'text'
      },
      responseParams: {
        id: 'id',
        name: 'subName'
      },
      responseKey: {
        id: 'id',
        name: 'subName'
      }
    }
  },
  created () {
    this.$setBreadcrumb('设置项目授权应用')
  },
  computed: {
    communityExtraParams () {
      return {
        regionId: this.form.regionId
      }
    }
  },
  methods: {
    selectCommunity () {
      if (this.form.regionId) {
        this.showCommunityMultiSelect = true
      } else {
        this.$message.info('请先选择租户')
      }
    },
    select () {
      if (this.form.regionId) {
        this.showMultiSelect = true
      } else {
        this.$message.info('请先选择租户')
      }
    },

    submitBefore (data) {
      let subIds = []
      data.subIds.forEach(item => {
        subIds.push(item.id)
      })
      data.subIds = subIds
      let communityIds = []
      data.communityIds.forEach(item => {
        communityIds.push(item.id)
      })
      data.communityIds = communityIds
      return true
    },
    tenantChange (option, echo) {
      if (!echo) {
        if (this.form.communityIds && this.form.communityIds.length) {
          this.form.communityIds = []
        }
        if (this.form.subIds && this.form.subIds.length) {
          this.form.subIds = []
        }
      }
    }
  }
}
</script>
