var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "GrantApplicationTenantForm-wrapper" },
    [
      _c(
        "form-panel",
        {
          attrs: {
            submitUrl: _vm.submitUrl,
            form: _vm.form,
            submitBefore: _vm.submitBefore,
          },
        },
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "设置项目授权应用" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "选择租户",
                        prop: "regionId",
                        rules: [
                          {
                            required: true,
                            message: "请选择租户",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: { placeholder: "选择租户" },
                            on: { onChange: _vm.tenantChange },
                            model: {
                              value: _vm.form.regionId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "regionId", $$v)
                              },
                              expression: "form.regionId",
                            },
                          },
                          "v-select2",
                          _vm.regionParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "选择项目",
                        prop: "communityIds",
                        rules: [
                          {
                            required: true,
                            message: "请选择项目",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("chosen-list-panel", {
                        attrs: { list: _vm.form.communityIds },
                        on: {
                          "update:list": function ($event) {
                            return _vm.$set(_vm.form, "communityIds", $event)
                          },
                          select: _vm.selectCommunity,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "授权应用" } },
                    [
                      _c("chosen-list-panel", {
                        attrs: { list: _vm.form.subIds, textAs: "subName" },
                        on: {
                          "update:list": function ($event) {
                            return _vm.$set(_vm.form, "subIds", $event)
                          },
                          select: _vm.select,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          title: "项目列表",
          isShow: _vm.showCommunityMultiSelect,
          searchUrl: _vm.getCommunityListURL,
          headers: _vm.communityHeaders,
          searchParams: _vm.form,
          responseParams: _vm.communityResponseParams,
          responseKey: _vm.communityResponseKey,
          backFill: _vm.form.communityIds,
          extraParams: _vm.communityExtraParams,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.showCommunityMultiSelect = $event
          },
          "update:is-show": function ($event) {
            _vm.showCommunityMultiSelect = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.form, "communityIds", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.form, "communityIds", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "项目名称" },
                  model: {
                    value: _vm.form.communityName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "communityName", $$v)
                    },
                    expression: "form.communityName",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("multi-select", {
        attrs: {
          title: "应用列表",
          isShow: _vm.showMultiSelect,
          searchUrl: _vm.getApplicationListURL,
          headers: _vm.headers,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          extraParams: _vm.communityExtraParams,
          backFill: _vm.form.subIds,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.showMultiSelect = $event
          },
          "update:is-show": function ($event) {
            _vm.showMultiSelect = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.form, "subIds", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.form, "subIds", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "应用名称" },
                  model: {
                    value: _vm.searchParams.subName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "subName", $$v)
                    },
                    expression: "searchParams.subName",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "应用类别", options: _vm.subHandleOps },
                  model: {
                    value: _vm.searchParams.subHandle,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "subHandle", $$v)
                    },
                    expression: "searchParams.subHandle",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "应用类型", options: _vm.subTypeOps },
                  model: {
                    value: _vm.searchParams.subType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "subType", $$v)
                    },
                    expression: "searchParams.subType",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "应用描述" },
                  model: {
                    value: _vm.searchParams.subNote,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "subNote", $$v)
                    },
                    expression: "searchParams.subNote",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }